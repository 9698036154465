import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import myImg from "../Assets/avatar3.png";
import { AiFillGithub, AiFillMail, AiFillPushpin, AiOutlineMail } from 'react-icons/ai';
import { FaIdCard } from 'react-icons/fa';
import React from 'react';
const stickyStyle = {
    position: 'sticky',
    top: '63px'
};

const SideBar = () => {
    return <div className="mt-5 no-print" style={stickyStyle} >
        <div className='text-center'>
            <Image src={myImg} roundedCircle width={150} />
            <h4 className="mt-3">Duy H. Ho</h4>
            <p style={{ fontSize: '1em' }}>Ph.D. Candidate at the School of Science and Engineering, University of Missouri-Kansas City (UMKC), Kansas City, MO, USA</p>
        </div>


        <ListGroup variant="flush" className="mt-4">
            <ListGroup.Item>
                <Row>
                    <Col xs={1}><AiFillPushpin style={{ color: 'var(--imp-text-color)', marginBottom: 3 }} /></Col>
                    <Col>Kansas City, MO, USA</Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col xs={1}><AiOutlineMail style={{ color: 'var(--imp-text-color)', marginBottom: 3 }} /></Col>
                    <Col>dhh3hb@umsystem.edu</Col>
                </Row>
            </ListGroup.Item>

            <ListGroup.Item>
                <Row>
                    <Col xs={1}><AiFillGithub style={{ marginBottom: 2 }} /></Col>
                    <Col>
                        <a href="https://github.com/duyhho" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            GitHub
                        </a>
                    </Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col xs={1}>
                        <Image width={17} height={17}
                            src="https://img.icons8.com/color/48/google-scholar--v3.png" alt="google-scholar--v3"
                            style={{ marginBottom: 2 }} /></Col>
                    <Col>
                        <a href="https://scholar.google.com/citations?hl=en&user=FwwK_JQAAAAJ" target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}>
                            Google Scholar
                        </a>
                    </Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
                <Row>
                    <Col xs={1}><FaIdCard style={{ marginBottom: 3 }} /></Col>
                    <Col>
                        <a href="https://orcid.org/0000-0002-3368-9778" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            ORCID
                        </a>
                    </Col>
                </Row>
            </ListGroup.Item>
            {/* Uncomment other links as needed */}
        </ListGroup>

    </div>



}
export default SideBar;