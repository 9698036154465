import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineComment,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";
import { FaComment, FaRegComment } from "react-icons/fa";
import { Form } from 'react-bootstrap';

function NavBar({ isToggled = true,
  setIsToggled = null,
  isWide = false,
  setIsWide = null
}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  // const [isToggled, setIsToggled] = useState(false);

  function scrollHandler() {
    console.log('window.scrollY', window.scrollY)
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  useEffect(() => {
    scrollHandler();
    // Add scroll event listener when component mounts
    window.addEventListener("scroll", scrollHandler);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []); // Empty dependency array ensures this runs once on mount and unmount


  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky no-print" : "navbar no-print"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          {/* <img src={logo} className="img-fluid logo" alt="brand" /> */}
          {/* <div class="circle-logo"> */}
          <b
            style={{ marginLeft: 10, marginRight: 10, fontSize: '1.2em' }}
          >
            Duy Ho</b>
          {/* </div> */}
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/projects"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Projects
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> CV
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/research-statement"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Research Statement
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <div className='nav-form' style={{ fontWeight: isToggled ? "bold" : 'normal' }}>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={isToggled ? "Academic Style (On)" : "Academic Style (Off)"}
                    checked={isToggled}
                    onChange={() => {
                      const newToggleState = !isToggled;
                      setIsToggled(newToggleState);
                      localStorage.setItem('websiteVersionToggle', newToggleState.toString());
                    }}
                  />
                </Form>

              </div>
              <p style={{ fontSize: '0.7em' }}>
                Page Width: {" "}
                <span
                  className={`toggle-option ${!isWide ? 'active' : ''}`}
                  onClick={() => setIsWide(false)}
                >
                  Narrow
                </span>
                {" | "}
                <span
                  className={`toggle-option ${isWide ? 'active' : ''}`}
                  onClick={() => setIsWide(true)}
                >
                  Wide
                </span>
              </p>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                href="https://soumyajitblogs.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                <ImBlog style={{ marginBottom: "2px" }} /> Blogs
              </Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/chat"
                onClick={() => updateExpanded(false)}
              >
                <FaRegComment style={{ marginBottom: "3px" }} /> Chat
              </Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/soumyajit4419/Portfolio"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
