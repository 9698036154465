import React, { useState, useEffect, useMemo } from 'react';
import CompactChatWindow from './CompactChatWindow';
import { useClient } from '../../hooks/useClient'; // Adjust the import path as necessary
import { BsChatFill, BsChatLeft, BsChatLeftDotsFill, BsChatLeftFill, BsFillChatFill, BsFillChatLeftFill } from 'react-icons/bs';
import { useChatContext } from 'stream-chat-react';
import MessengerChat from './MessengerChat';
import { Button } from 'react-bootstrap';
import { ThreeDots } from 'react-loading-icons';

const CompactChat = ({ currentUser, loading, isChatOpen = false, setChatOpen }) => {
    // const [isChatOpen, setChatOpen] = useState(false);
    const [isMessengerOpen, setMessengerOpen] = useState(false);   // New state for the messenger chat
    const { client } = useChatContext();

    const toggleChat = () => setChatOpen(!isChatOpen);
    const toggleMessenger = () => setMessengerOpen(!isMessengerOpen);  // Toggle function for the messenger chat
    console.log('isChatOpen', isChatOpen)
    useEffect(() => {
        console.log('isChatOpen', isChatOpen)

    }, [isChatOpen])
    return (
        <>
            <ChatIcon onClick={toggleChat} isChatOpen={isChatOpen} />
            {isChatOpen && (
                <CompactChatWindow
                    currentUser={currentUser}
                    loading={loading}
                    chatClient={client}
                    setMessengerOpen={setMessengerOpen}
                />
            )}
            {isMessengerOpen && (
                <MessengerChat
                    currentUser={currentUser}
                    loading={loading}
                    isMessengerOpen={isMessengerOpen}
                    setMessengerOpen={setMessengerOpen}
                />
            )}
        </>
    );
};

const ChatIcon = ({ onClick, isChatOpen }) => {
    const initialStyle = {
        fontSize: '80px',
        color: 'var(--imp-text-color)',
    };
    const focusedStyle = {
        fontSize: '90px',
        color: 'var(--imp-text-color)',
    };
    const blueColor = 'var(--imp-text-color)';
    const [iconStyle, setIconStyle] = useState(initialStyle);
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Button
            className="chat-icon no-print"
            onClick={onClick}
            onMouseEnter={() => {
                setIsFocused(true)
                console.log('mouse enter!');
            }}
            onMouseLeave={() => {
                setIsFocused(false)
            }}
            variant="link"
        >
            <span className="wave" role="img" aria-labelledby="wave">
                {!isFocused && !isChatOpen &&
                    <div style={{ position: 'relative' }}>
                        <BsChatLeft style={initialStyle} />
                        <span style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -80%)',
                            fontSize: '20px', // adjust as needed
                            color: blueColor,
                            pointerEvents: 'none' // ensures that the text doesn't interfere with button interactions
                        }}>
                            Chat
                        </span>
                    </div>
                }
                {isFocused && !isChatOpen &&
                    <div style={{ position: 'relative' }}>
                        <BsChatLeftFill style={focusedStyle} />
                        <span style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -80%)',
                            fontSize: '20px', // adjust as needed
                            fontWeight: 'bold',
                            color: 'white',
                            pointerEvents: 'none' // ensures that the text doesn't interfere with button interactions
                        }}>
                            Chat
                        </span>
                    </div>
                }
                {isChatOpen && <BsChatLeftFill style={focusedStyle} />}
                {isChatOpen &&
                    <div style={{ position: 'absolute', top: '37%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <ThreeDots stroke={blueColor} width={50} height={50} />
                    </div>}
            </span>
        </Button>
    );
};


export default CompactChat;
