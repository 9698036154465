import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import soar from "../../Assets/Projects/soar.png";
import soarpaper from "../../Assets/Projects/soar-paper.PNG";
import suicide from "../../Assets/Projects/suicide.png";
import welisten from "../../Assets/Projects/WeListen.jpg";
import WeLiveVR from "../../Assets/Projects/WeLiveVR.png";
import DeepLite from "../../Assets/Projects/deeplite.PNG";
import Graphevo from "../../Assets/Projects/graphevo.PNG";
import OpenComm from "../../Assets/Projects/opencomm.PNG";
import bigdataframework from "../../Assets/Projects/bigdataframework.PNG";
import Abandoned from "../../Assets/Projects/abandoned.PNG";
import casestudy from "../../Assets/Projects/casestudy.PNG";
import community from "../../Assets/Projects/community.PNG";
import cybervizvideo from "../../Assets/Projects/cyberviz-demo.mp4";
import cybervizvideo2 from "../../Assets/Projects/cyberviz-demo-2.mp4";
import cyberviz from "../../Assets/Projects/cyberviz1.jpeg";
import collabfireagent from "../../Assets/Projects/Collab Fire Agent.gif";
import dynahealth from "../../Assets/Projects/Dynahealth.png";
import GenCyber from "../../Assets/Projects/GenCyber.gif";
export const allProjects = [
  {
    imgPath: collabfireagent,
    title: "Dynamic Navigation Robot (Active Research)",
    description: `The agent's task is to navigate a multi-room environment to locate and extinguish a fire. The agent can either tackle the fire alone or collaborate with other agents to expedite the process. Collaboration is key, as the collective effort of multiple agents significantly increases efficiency.
    The optimal strategy utilizes a combination of Proximal Policy Optimization (PPO), Curiosity-driven Exploration, and Incentive-based Curriculum Learning. \n
    We have designed a curriculum that scales the complexity of room settings, starting from a basic 2-room configuration. The agent progresses to higher levels (3-, 4-, and 5-room settings) once a proficiency threshold is achieved, ensuring foundational skills are solidified before introducing additional complexity.\n\n
    The agent undergoes training in randomized 2- to 5-room settings for 5 million steps. Rewards are assigned upon reaching the fire: +2 for extinguishing the fire, and an additional +2 for each activated agent. A slight negative reward is issued throughout the navigation to incentivize quick and efficient task completion.
    `,
    ghLink: "https://github.com/duyhho/AI-Empowered-Robotics/blob/main/README.md#dynamic-environment-generation--navigation-agent-unity",
    demoLink: "https://www.youtube.com/watch?v=4ZA4PJYXDiA"
  },
  {
    imgPath: "", // Assuming no image path for now
    title: "Digital Twin Navigation JetBot (Active Research)",
    description: `This project develops a Digital Twin navigation system for the NVIDIA JetBot, integrating Unity 3D to simulate real-world scenarios with a focus on obstacle detection, avoidance, and guidance. 
    By creating a virtual replica of the JetBot in Unity 3D, the system allows for the safe and efficient testing of navigation algorithms under varied conditions, including complex obstacle-rich environments. The simulation facilitates the study and enhancement of the JetBot's response mechanisms, crucial for reliable obstacle detection and avoidance. 
    A bidirectional communication setup between the virtual and physical JetBot ensures that insights and adaptations from the simulated trials directly inform real-world operations.
    This innovative approach speeds up the development of effective navigation strategies, reduces the risks and costs of physical testing, and advances the field of autonomous robotic navigation. 
    \n\nDeveloping Features: \n
Real-World Simulation: Utilizes Unity 3D for creating realistic scenarios for testing and development.\n
Obstacle Detection and Avoidance: Focuses on improving the JetBot's ability to navigate through challenging environments.\n
Bidirectional Communication: Ensures that the virtual and real-world JetBots can exchange data and learnings in real-time.\n
Risk Reduction: Minimizes the risks and costs associated with physical testing.`,
    ghLink: "https://github.com/duyhho/Digital-Twins",
    demoLink: "https://www.youtube.com/watch?v=WV505kwxo0U",
    youtubeURL: "https://www.youtube.com/embed/R3HDMm4rkGE?si=JxiQF3btkJnuqdPU",
  },
  {
    imgPath: "", // Assuming no image path for now
    title: "Digital Twin Surgery Robot (MUS Procedure)",
    description: `In our study, we investigate the seamless integration of virtual environments with actual surgical practices, crafting a sophisticated platform that employs advanced robot learning algorithms to mirror the functionality of real-world surgical robots. This research concentrates on reaching higher levels of robotic autonomy, with a particular emphasis on Level 2 (Task-level Autonomy) and Level 3 (Conditional Autonomy), as delineated in the medical robotics literature. Through the strategic use of Augmented Reality (AR) and Virtual Reality (VR), we offer real-time feedback and explicit visual guidance, laying the groundwork for a comprehensive and immersive training environment for surgical procedures.
    Our focus extends to procedures such as the Mid Urethral Sling (MUS), where the goal is to refine surgical training, enhance patient outcomes, and increase the precision of the surgeries. This objective is supported by the interactive relationship between digital twins, which simulate and dissect complex surgical tasks, and the physical robotic arms that bring these simulations to life in MUS operations.`,
    ghLink: "https://github.com/duyhho/Vuforia-HoloLens2-v2020",
    demoLink: "https://www.youtube.com/watch?v=DfgDN_G0QYI",
    youtubeURL: "https://www.youtube.com/embed/DfgDN_G0QYI?si=YWPMYcM6BEAi9UEJ",
  },
  {
    imgPath: soar,
    title: "SOAR Portal",
    description: `The SOAR Portal is a tailored platform designed to foster collective impact and systemic change for post-secondary students with disabilities. Throughout the academic year, periodic questionnaires will appear on users' dashboards. These questionnaires gather data on individual profiles, goals, activities, and advancements. By analyzing this information, the platform aims to monitor both the challenges and achievements of students, enhancing mentorship programs, amplifying communication concerning alliance objectives, and distributing relevant resources.`,
    webLink: "https://soar-ai.com",
    paperLink: "https://link.springer.com/chapter/10.1007/978-3-031-35897-5_26",
  },
  {
    imgPath: welisten,
    title: "WeListen",
    description: `WeListen: Community Voices through Human-Machine Collaboration and Interpretable AI for Community-in-the-loop.
    Funded by Jackson County's Community Action Research: An Opportunity to Create Challenge, this study is centered around the innovative "community-in-the-loop" concept, harnessing the power of AI technologies to foster effective two-way communication and active engagement between communities and decision-makers. Our comprehensive pipeline, named "WeListen," embodies the principle of human-in-the-loop, emphasizing human judgment in tandem with AI capabilities to overcome technical challenges such as scaling up data collection and producing interpretable analytics. We contribute new algorithms and undertake rigorous testing of model outputs against human benchmarks, offering a robust and scalable approach to data analysis. A specially designed anonymous data collection application tailored for virtual focus groups has been deployed and evaluated, showing promise for further development. Addressing the often encountered trade-off between precision and recall for class imbalance in coding content features, we showcase two distinct approaches: GloVe 300 minimal pooling, which leans towards high confidence (accuracy), and zero-shot learning, which offers a less conservative, more encompassing approach. By merging human expertise and AI automation, our study provides a compelling insight into the future of effective and efficient content analysis, underscoring the potential to achieve refined and reliable results.
    
    As the lead technical developer and co-researcher, I managed technical teams to develop a specialized mobile application for speech and multilingual data collection. This project, an interdisciplinary collaboration with UMKC’s School of Journalism and Johnson County’s Community Action Research Opportunities, played a pivotal role in facilitating public engagement through focus meetings and influencing policy decisions. The initiative's key achievement was the publication of a case study using NLP to extract consumer insights from tweets for public health crises, showcasing the practical applications of AI in public health and community insights.
    `,
  },
  {
    imgPath: GenCyber,
    title: "National Science Agency - GenCyber",
    description: `
    My role as an instructor for the NSA-sponsored GenCyber summer camps illustrates a profound engagement with the next generation of cybersecurity talent. By merging educational content with cutting-edge technology, I have contributed to shaping the cybersecurity landscape, preparing young minds to navigate and secure the digital future.
    
    Main Responsibilities: 

    •  Technical Educator & Mentor. Designed and taught a cybersecurity curriculum
using Python and Minecraft, including setting up and managing Minecraft servers.

    • Technical Developer: Developed blockchain communication mechanisms and Java addon
plugins for educational purposes in Minecraft.

    `,
    slideLink: "https://umkc.box.com/s/t1ctm2xvcfjwtsr62ix4k7cr5e3me2cn",
    demoLink: "https://umkc.box.com/s/o21xah1jw8216rkmolpm2l9e3x5on4tz",
    downloadLink: "https://umkc.box.com/s/iu6t04212ml7ok7ex7ecb5xnzhpoxbtu"
  },
  {
    imgPath: WeLiveVR,
    title: "WeLive (VR)",
    description: `Our project, WeLive,  introduces users to an interactive world in Metaverse where they are  not only able to socialize with other users in real time but also work, practice, train, study, play, entertain, and experiment via immersive simulation, accurate hand tracking and precise movement, and interaction with realistic 3D environments and complex object designs. 
                            
    Award(s):
    (v1) - Voice-enabled Multiplayer - UMKC Hack-A-Roo (T-Mobile's Specialized AR/VR Track) - Spring 2022: 1st Place\n
    (v2) - Immersive Single-player and LipSync - UMKC Hack-A-Roo (T-Mobile's Specialized AR/VR Track) - Fall 2022: 3rd Place\n
`,
    ghLink: "https://github.com/duyhho/WeLiveVR",
    slideLink: "https://mailmissouri-my.sharepoint.com/:p:/g/personal/dhh3hb_umsystem_edu/ET8aXpMkpe5GvL5top-3nJkBeEefYzN71NyTqYsGgAPt9w?e=6oQqcV",
    demoLink: "https://youtu.be/iasvHwjaKyU"
  },
  {
    imgPath: "", // Assuming no image path for now
    title: 'MetaLens',
    description: `Metaverse”, a new form of social life via virtual reality, is a new ambitious vision set by Meta (formerly known as Facebook) that allows users to socialize in a virtual world. It is a groundbreaking concept in social networking in which we will perform many activities such as working, playing, studying, and interacting with each other in an immersive way.
    Thus, our project, MetaLens, plans to focus primarily on a vision in the future where daily lives are enhanced with technology and Artificial Intelligence (AI). Our lives will be surrounded by robots, drones, and other automatic systems that make our tasks a lot more convenient. Via AI, Deep Learning, Sentiment Analysis, and Object Recognition, MetaLens aims to help not only the citizens but also the government be aware of their citizen’s well-being and the city’s overall performance to derive appropriate solutions.
    
    Award(s):
UMKC Hack-A-Roo - Fall 2021: 1st Place`,
    slideLink: "https://mailmissouri-my.sharepoint.com/:p:/g/personal/dhh3hb_umsystem_edu/ESgtQ36AxjpGjiy9yIZMPxEBW0nTIMS14dP1CtJY8M9EEA?e=Dresha",
    downloadLink: "https://mailmissouri-my.sharepoint.com/:u:/g/personal/dhh3hb_umsystem_edu/EVQ8xPqQ5_9Fs4H9PVDtK_gBeOtwjIBnqCKy6SjNmFW6Hg?e=mv7hwH",
    youtubeURL: "https://www.youtube.com/embed/QzLQXcuTMQ0?si=FI7v7KSBPXx7UWxy",
    ghLink: "https://github.com/benamreview/HackARoo-Fall2021-VR-Assets",
    colabLink: "https://colab.research.google.com/drive/1E7223DY3RbS-OVM6cZ3VO7MKQxmZ5XZe?usp=sharing",

  },
  {
    imgPath: dynahealth,
    title: "DynaHealth",
    description: `Health insurance is one of the most important protections for an individual. Having a health insurance plan not only helps ease the mind but also covers a significant amount of cost in critical or unexpected conditions such as illness and accident. However, not everyone has easy access to and knowledge about health insurance in a convenient and informative way. Thus, users may not have sufficient awareness about the topic to make a decision. Moreover, data from many sources are not represented in a transparent or meaningful manner, leading to uncertainty and confusion.\n
    \n\nAward(s): \n
UMKC Hack-A-Roo - Spring 2021: 1st Place\n
    `,
    ghLink: "https://github.com/duyhho/Dynahealth-Backend",
    slideLink: "https://mailmissouri-my.sharepoint.com/:p:/g/personal/dhh3hb_umsystem_edu/EYw9IUSBXptEod2dELPhRTkBtCazIj14eI6VP7nLVKLp_Q?e=SzglJo",
    demoLink: "https://vimeo.com/539498222",

  },
  {
    imgPath: "", // Assuming no image path for now
    title: "Soulful.AI",
    description: `In response to the unprecedented challenges posed by the global crisis, including physical, financial, and psychological impacts, our team has developed an AI chatbot. This chatbot aims to assist individuals feeling isolated or depressed, offering them an accessible digital companion for momentary relief and support during these trying times.\n An ensemble of models was built on Google Colaboratory (with enough GPU computing power), consisting of 4-5 different models suitable for different knowledge domains.​

    The NLP framework is HuggingFace, the leading open-source framework for NLP solutions using Transformers built on top of PyTorch. We utilized the DialoGPT model to handle human-like conversations​
    
    The mobile application was built on Android via the React Native framework, one of the most popular choices for developing robust cross-platform applications using JavaScript.  The multiplatform-compatible framework was developed by Facebook for ease of building native applications.
    \n\nAward(s): \n
UMKC Hack-A-Roo - Fall 2020: 1st Place\n`,
    ghLink: "https://github.com/ngchieu857529/emotionalchatbot",
    slideLink: "https://1drv.ms/p/s!AmtRtv41fFQLhfc0AkggDc3ipIjwPw?e=y4hYEu",
    youtubeURL: "https://www.youtube.com/embed/s5qXJlxOg-8?si=vY5maCN7g5Bt4Z-c",

  },
  {
    imgPath: cyberviz,
    title: "CyberViz (Web App + AR)",
    description: `An envisioned solution of a corporate network visualized in an intuitive fashion as a cityscape with the goal of supporting the cybersecurity analysis process. Network traffic and volume are dynamically fetched and visualized in real-time through a web application and augmented-reality (AR) platform via Microsoft HoloLens 2. This application transforms the cybersecurity and incident response analysis into a dynamic and game-like experience within an inventive visual realm, aiming to alleviate the tedium and fatigue commonly experienced by analysts. It enhances the efficacy of their analyses. Furthermore, it grants exceptional flexibility in the execution of analytical tasks by integrating gamification into the analysts' workflow, yet it preserves the option for them to revert to conventional methods on their usual platforms as needed.`,
    ghLink: "https://github.com/duyhho/CyberViz-Assets",
    demoLink: "cybervizvideo",
    demoLink2: "cybervizvideo2",

  }
];

function Projects() {
  return (
    <Container fluid className="project-section" style={{ textAlign: 'center' }}>

      <h1 className="project-heading">
        My <strong className="purple">Publications </strong>
      </h1>
      <p style={{ color: "black" }}>
        Here are my accepted papers.
      </p>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={soarpaper}
            isBlog={false}
            title='Surmounting Obstacles for Academic Resilience: A Dynamic Portal for Supporting an Alliance of Students with Disabilities'
            description="Description of the development and use of a dynamic portal
              for supporting an alliance of colleges and universities focused on supporting students with disabilities and transitioning to careers in science
              and technology. Called SOAR, the portal is designed to support separate institutes achieve collective impact through shared measures. Significant aspects of SOAR are the user-driven design with three different
              communication roles, dynamic generation of survey forms, the ability to
              schedule surveys, collecting data through the surveys, and data presentation through dynamic chart generation. SOAR utilizes and advances
              the best practices of Universal Access and is central to the alliance’s
              ability to empower individuals with disabilities to live their best lives.
              One of the most interesting features is the ability for different institutes
              to customize their forms and collect campus-relevant data that can be
              changed and the application of machine learning to produce the dynamic
              chart generation. SOAR allows the alliance to meet individual campus
              needs and the reporting and evaluation needs of the National Science
              Foundation."
            paperLink="https://link.springer.com/chapter/10.1007/978-3-031-35897-5_26"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={DeepLite}
            isBlog={false}
            title="DeepLite: Real-Time Deep Learning Framework for Neighborhood Analysis"
            description="In this paper, we propose a new framework, DeepLite, for real-time deep learning on the edge. In DeepLite, a network of multiple deep learning models is designed to conduct the context-aware inferencing using real-time deep learning technologies. Comprehensively, DeepLite has several innovative concepts as follows: 1) an inference network of deep learning models/containers, 2) invocation of a new model based on the output of the previous model's inferencing, 3) intelligent containers for models, 4) plug-and-play model/container, and 5) microservice architecture on the edge. DeepLite has been evaluated via a case study, NeighborNets which was based on an inference network of several deep learning models deployed to edge devices for computer vision, e.g., object detection algorithms to compute a diverse range of aspects, such as house types, level of greenery, house age, traffic conditions, and the types of recreational facilities."
            paperLink="https://ieeexplore.ieee.org/abstract/document/9005651"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={Graphevo}
            isBlog={false}
            title="Graphevo: Characterizing and understanding software evolution using call graphs"
            description="In this paper, we propose a new framework, DeepLite, for real-time deep learning on the edge. In DeepLite, a network of multiple deep learning models is designed to conduct the context-aware inferencing using real-time deep learning technologies. Comprehensively, DeepLite has several innovative concepts as follows: 1) an inference network of deep learning models/containers, 2) invocation of a new model based on the output of the previous model's inferencing, 3) intelligent containers for models, 4) plug-and-play model/container, and 5) microservice architecture on the edge. DeepLite has been evaluated via a case study, NeighborNets which was based on an inference network of several deep learning models deployed to edge devices for computer vision, e.g., object detection algorithms to compute a diverse range of aspects, such as house types, level of greenery, house age, traffic conditions, and the types of recreational facilities."
            paperLink="https://ieeexplore.ieee.org/abstract/document/9005560"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={OpenComm}
            isBlog={false}
            title="OpenComm: Open community platform for data integration and privacy preserving for 311 calls"
            description="Local governments are increasingly leveraging administrative data to drive performance. Likewise, cities are interested in improving responsiveness to citizens’ demands and cost savings through data analytics. However, city managers face many challenges when utilizing secondary data, such as 311 call records and the US Census. The challenge of interest to the current study is boundary issues as a result of data being collected at divergent geographic levels over different time horizons. Accordingly, an inductive analytical methodology was developed to create units of analysis that were both pragmatically and analytically appropriate for city managers and local policymakers. We created an open data analytics framework called OpenComm to harmonize administrative and secondary data using administrative data derived from Kansas City, Missouri. This framework produced robust inferences regarding the spatial and temporal aspects for the communities. Privacy-preserving technology, in particular, has been applied to public data to protect community privacy. The findings illustrate the power of inductive data aggregation, leading to empirical insights into hidden patterns of city service disparity over a decade-long time horizon."
            paperLink="https://www.sciencedirect.com/science/article/pii/S2210670722001858"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={bigdataframework}
            isBlog={false}
            title="Big Data Analytics Framework for Predictive Analytics using Public Data with Privacy Preserving"
            description="There are increasingly leveraging public data with cities increasingly interested in driving both responsiveness to citizen demands and cost savings through data analytics. As public managers seek to augment existing data sources, such as 311 complaints, with existing secondary data, such as US Census products, severe challenges exist. This paper considers the problems inherent in data being collected at divergent geographic levels over different time horizons. An inductive analytical methodology is developed to create units of analysis that are both useful and analytically appropriate for public managers and policy leaders in urban areas. A big data analytics framework for public data, called BDAP, was presented predictive analytics for community need considering data the spatial and temporal location while addressing the data issues such as missing values, privacy-preserving, and predictive modeling. The findings illustrate the power of inductive data curation and privacy-preserving leading to benefits to the big data community. An application for the Open Data Platform was developed using KCMO’s 311 data, crime data and census data."
            paperLink="https://ieeexplore.ieee.org/abstract/document/9671997"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={Abandoned}
            isBlog={false}
            title="Deep Learning Visual Methods for Identifying Abandoned Houses"
            description="Housing abandonment contributes to neighborhood decline and disinvestment. Abandonment has plagued large metropolitan areas for decades, yet quantifying the scope and impact of abandonment has proven costly and elusive. This study introduces an innovative approach to detect and measure abandoned houses using technology innovations without requiring significant resource commitments. It presents a system of detecting abandoned houses leveraging deep learning models for image classification, building an ensemble model that considers both global and local contexts to identify abandoned structures. This study takes imagery and structure data from multiple sources and uses transfer learning in a three-stage ensemble approach to identify abandoned houses. Four deep learning models are constructed for this study: the ResNet-50 model, an incremental knowledge model, a hybrid approach, and a check model. Results from the different models are compared and analyzed to identify the visual characteristics of houses that improve or degrade each method’s accuracy. The methodology presented herein is scalable and could be applied in other neighborhoods and communities. The data generated by this method will empower communities and cities to design more effective strategies to address housing abandonment."
            paperLink="https://www.jstor.org/stable/48680615"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={casestudy}
            isBlog={false}
            title="A case study of using natural language processing to extract consumer insights from tweets in American cities for public health crises"
            description="The COVID-19 pandemic was a “wake up” call for public health agencies. Often, these agencies are ill-prepared to communicate with target audiences clearly and effectively for community-level activations and safety operations. The obstacle is a lack of data-driven approaches to obtaining insights from local community stakeholders. Thus, this study suggests a focus on listening at local levels given the abundance of geo-marked data and presents a methodological solution to extracting consumer insights from unstructured text data for health communication."
            paperLink="https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-023-15882-7"
          />
        </Col>
        <Col md={6} className="project-card">
          <ProjectCard
            imgPath={community}
            isBlog={false}
            title="Community-in-the-loop: Creating Artificial Process Intelligence for Co-production of City Service"
            description={`Communities have first-hand knowledge about community issues. This study aims to improve the efficiency of social-technical problem-solving by proposing the concept of "artificial process intelligence," based on the theories of socio-technical decision-making. The technical challenges addressed were channeling the communication between the internal-facing and external-facing 311 categorizations. Accordingly, deep learning models were trained on data from Kansas City's 311 system: (1) Bidirectional Encoder Representations from Transformers (BERT) based classification models that can predict the internal-facing 311 service categories and the city departments that handle the issue; (2) the Balanced Latent Dirichlet Allocation (LDA) and BERT clustering (BLBC) model that inductively summarizes residents' complaints and maps the main themes to the internal-facing 311 service categories; (3) a regression time series model that can predict response and completion time. Our case study demonstrated that these models could provide the information needed for reciprocal communication, city service planning, and community envisioning. Future studies should explore interface design like a chatbot and conduct more research on the acceptance and diffusion of AI-assisted 311 systems.`}
            paperLink="https://dl.acm.org/doi/abs/10.1145/3555176
              "
          />
        </Col>
      </Row>
      <h1 className="project-heading">
        My <strong className="purple">Projects </strong>
      </h1>
      <p style={{ color: "black" }}>
        Here are a few projects I've worked on recently.
      </p>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        {allProjects.map(project => (
          <Col md={project.colSize || 4} className="project-card" key={project.title}>
            <ProjectCard
              imgPath={project.imgPath}
              isBlog={project.isBlog || false}
              title={project.title}
              description={project.description}
              ghLink={project.ghLink}
              demoLink={project.demoLink}
              youtubeURL={project.youtubeURL}
              webLink={project.webLink}
              colabLink={project.colabLink}
              slideLink={project.slideLink}
              downloadLink={project.downloadLink}
            // ...other properties
            />
          </Col>
        ))}
      </Row>

    </Container>
  );
}

export default Projects;
