import React, { useState, useEffect, useMemo } from 'react';
import {
    Chat,
    Channel,
    ChannelList,
    Window,
    ChannelHeader,
    MessageList,
    MessageInput,
    Thread,
    LoadingIndicator,
    useChatContext, useMessageInputContext
} from 'stream-chat-react';
import { useClient } from '../../hooks/useClient';
import { Button } from 'react-bootstrap';
import { CustomMessageInput } from './CompactChatWindow';
const apiKey = 'gawfgbhdkdeb';

const adminUser = {
    id: 'umkc',
    userName: 'Duy Ho',
    userToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidW1rYyJ9.5D7dCHEbRPjjhbfZWT2H3iwunnZcqAximzRU8noubkw',
    userImage: "https://i.ibb.co/3C55pYM/avatar2.png",
    role: 'admin'
};

const MessengerChat = ({ currentUser, loading, isMessengerOpen, setMessengerOpen }) => {
    // const [currentUser, setCurrentUser] = useState(null);
    // const [loading, setLoading] = useState(true);
    const user = useMemo(() => currentUser ? {
        id: currentUser.id,
        name: currentUser.userName,
        image: currentUser.userImage,
        role: currentUser.role
    } : null, [currentUser]);
    const CustomList = (props) => {
        const { children, error, loading, LoadingErrorIndicator, LoadingIndicator } = props;

        if (error) {
            return <LoadingErrorIndicator text='Loading Error - check your connection.' type='connection' />;
        }

        if (loading) {
            return <LoadingIndicator />;
        }

        return <div>
            {user && (
                <div style={{ marginLeft: 10 }}>
                    <p>
                        Your Username: <b style={{ color: 'var(--imp-text-color)' }}>{user.name}</b> - [<code style={{ color: 'gray' }}>{user.id}</code>] ({user.role})
                    </p>

                    <small>
                        Note: For {" "}<span style={{ textDecoration: 'underline' }}>direct messages</span>, please try to keep this window open. Your username will be
                        <span style={{ fontWeight: 'bold' }}> randomized</span> on refresh and the conversation will be {" "}
                        <span style={{ textDecoration: 'underline' }}>deleted</span>.
                    </small>
                </div>
            )}
            <div style={{ textAlign: 'center' }} >
                <Button size='sm' className='mb-1' onClick={() => setMessengerOpen(false)}>
                    Close Full Screen
                </Button>
            </div>

            {children}
        </div>;
    };



    const { client } = useChatContext();
    const chatClient = client;
    console.log('messenger context client', client.userID)

    const createFilters = () => {
        let baseFilters = {
            type: 'messaging',
            members: { $in: [chatClient?.userID] }
        };

        if (currentUser?.role === 'admin') {
            baseFilters = {
                ...baseFilters,
                last_message_at: { $gte: "2020-01-01T00:00:00.00Z" }
            };
        }

        return baseFilters;
    };
    if (loading || !chatClient) {
        return <LoadingIndicator />;
    }

    const filters = createFilters();
    const sort = { last_message_at: -1 };

    return <div className='full-chat-window'>
        <Chat client={chatClient} theme='messaging light'>
            <ChannelList
                List={CustomList}
                filters={filters}
                sort={sort} />
            <Channel>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    <CustomMessageInput currentUser={currentUser} />
                </Window>
                <Thread />
            </Channel>
        </Chat>
    </div>



};

export default MessengerChat;
