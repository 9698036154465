import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillAndroid } from "react-icons/ai";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import { FaReact } from "react-icons/fa";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
  SiUnity,
  SiPytorchlightning,
  SiPytorch,
} from "react-icons/si";
import { TbBrandGolang, TbBrandUnity } from "react-icons/tb";
import nodeJS from "../../Assets/Logos/nodejs.svg";
import reactlogo from "../../Assets/Logos/react-logo.svg";
import firebaseLogo from "../../Assets/Logos/firebase.svg";
import reactnativelogo from "../../Assets/Logos/react-native-logo.png";
import androidlogo from "../../Assets/Logos/android.svg";
import tensorflowlogo from "../../Assets/Logos/tensorflow.svg";
import blenderlogo from "../../Assets/Logos/blender_logo.png";
import googlecloudlogo from "../../Assets/Logos/googlecloud.png";
import jupyterlogo from "../../Assets/Logos/jupyterlogo.svg";
import vuforialogo from "../../Assets/Logos/vuforia_logo.png";
import mrtklogo from "../../Assets/Logos/mrtk logo.png";
import colablogo from "../../Assets/Logos/colab.svg";
import mlagents from "../../Assets/Logos/mlagents.jpg";
import ufactory from "../../Assets/Logos/ufactory.jpg";
import waveshare from "../../Assets/Logos/waveshare.svg";
import linux from "../../Assets/Logos/linux.svg";
import awslogo from "../../Assets/Logos/aws.png";
import vercel from "../../Assets/Logos/vercel.png";
import hololens2 from "../../Assets/Logos/hololens 2.png";
import metaquest2 from "../../Assets/Logos/meta_quest_logo.png";
import nextjslogo from "../../Assets/Logos/nextjs.png";
import crealitylogo from "../../Assets/Logos/creality_logo.webp";

import { Fade, Flip, Slide } from "react-reveal";





function Techstack() {
  return <>
    <Fade right cascade>
      <h1 className="project-heading">
        My <strong className="purple">Knowledge </strong>
      </h1>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>

        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-5px)' }}>
            <img src={reactlogo} width={80} className="img-fluid" alt="react" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-5px)' }}>
            <img src={reactnativelogo} width={80} className="img-fluid" alt="react native" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px) ' }}>
            <img src={androidlogo} width={80} className="img-fluid" alt="android studio" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          {/* <DiNodejs style={{ marginBottom: 10 }} /> */}
          <div style={{ transform: 'translateY(-5px)' }}>
            <img src={nodeJS} width={80} className="img-fluid" alt="nodeJS" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          {/* <DiNodejs style={{ marginBottom: 10 }} /> */}
          <div style={{ transform: 'translateY(-6px) translateX(4px)' }}>
            <img src={nextjslogo} width={120} className="img-fluid" alt="nodeJS" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          {/* <DiNodejs style={{ marginBottom: 10 }} /> */}
          <div style={{ transform: 'translateY(-6px) translateX(-3px)' }}>
            <img src={vercel} width={160} className="img-fluid" alt="nodeJS" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-3px)' }}>
            <svg
              width="55px"
              height="55px"
              viewBox="-27 10 310 310"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              preserveAspectRatio="xMidYMid"
            >
              <g>
                <path
                  d="M218.281037,90.106412 C268.572988,140.398363 268.572988,221.075034 218.281037,271.716235 C169.036835,322.008186 88.0109141,322.008186 37.7189632,271.716235 C-12.5729877,221.424284 -12.5729877,140.398363 37.7189632,90.106412 L127.825375,0 L127.825375,45.053206 L119.443383,53.4351978 L59.7216917,113.156889 C22.0027285,150.177353 22.0027285,210.946794 59.7216917,248.665757 C96.7421555,286.38472 157.511596,286.38472 195.230559,248.665757 C232.949523,211.645293 232.949523,150.875853 195.230559,113.156889 L218.281037,90.106412 Z M173.227831,84.5184175 C163.969338,84.5184175 156.463847,77.0129263 156.463847,67.7544338 C156.463847,58.4959413 163.969338,50.9904502 173.227831,50.9904502 C182.486323,50.9904502 189.991814,58.4959413 189.991814,67.7544338 C189.991814,77.0129263 182.486323,84.5184175 173.227831,84.5184175 Z"
                  fill="#EE4C2C"
                />
              </g>
            </svg>
          </div>

        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={tensorflowlogo} width={80} className="img-fluid" alt="tensorflow" />
          </div>
        </Col>

        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={googlecloudlogo} width={110} className="img-fluid" alt="Google Cloud Platform" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={jupyterlogo} width={80} className="img-fluid" alt="Jupyter" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-8px)' }}>
            <img src={colablogo} width={80} className="img-fluid" alt="Google Colab" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-5px)' }}>
            <img src={firebaseLogo} width={80} className="img-fluid" alt="Firebase" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiUnity style={{ marginBottom: 10 }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px) translateX(15px)' }}>
            <img src={vuforialogo} width={190} className="img-fluid" alt="Vuforia" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={hololens2} width={160} className="img-fluid" alt="Microsoft HoloLens 2" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={metaquest2} width={190} className="img-fluid" alt="Microsoft HoloLens 2" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={blenderlogo} width={180} className="img-fluid" alt="blender" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-8px) translateX(2px)' }}>
            <img src={crealitylogo} width={190} className="img-fluid" alt="blender" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={mrtklogo} width={180} className="img-fluid" alt="Vuforia" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={mlagents} width={180} className="img-fluid" alt="mlagents" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={ufactory} width={90} className="img-fluid" alt="ufactory" />
          </div>
        </Col>

        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={waveshare} width={80} className="img-fluid" alt="waveshare" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-7px)' }}>
            <img src={linux} width={80} className="img-fluid" alt="linux" />
          </div>
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <div style={{ transform: 'translateY(-5px)' }}>
            <img src={awslogo} width={80} className="img-fluid" alt="aws" />
          </div>
        </Col>
      </Row>
    </Fade>

  </>


}

export default Techstack;
