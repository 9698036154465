import React, { useState, useEffect } from "react";

import { Container, Row, Col, Form } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  const [isToggled, setIsToggled] = useState(true);

  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer no-print">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed & Developed by Duy Ho (2023)
            (inspired by<a href="https://github.com/soumyajit4419" className="githubIcon">
              <AiFillGithub style={{ marginBottom: 3, marginLeft: -2, fontSize: 18 }} />
            </a>)
          </h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>dhh3hb@umsystem.edu</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/duyhho"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            {/* <li className="social-icons">
              <a
                href=""
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li> */}
            {/* <li className="social-icons">
              <a
                href=""
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li> */}
            {/* <li className="social-icons">
              <a
                href=""
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li> */}
            <li className='social-icons'>
              <a
                href="https://orcid.org/0000-0002-3368-9778"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ai ai-google-scholar-square" style={{ color: 'white', transform: 'translateY(2px)' }}></i>
              </a>
            </li>
            <li className='social-icons' style={{ color: 'white', fontWeight: isToggled ? 'bold' : 'none' }}>
              {/* <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Academic Style"
                  checked={isToggled}
                  onChange={() => {
                    const newToggleState = !isToggled;
                    setIsToggled(newToggleState);
                    localStorage.setItem('websiteVersionToggle', newToggleState);
                  }}
                />
              </Form> */}
            </li>

          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
