import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaCode, FaFilePdf, FaFilePowerpoint } from "react-icons/fa";
import colablogo from "../../Assets/Logos/colab.svg";

function ProjectCardsSimplify(props) {
  return (
    <Card>
      {props.imgPath && <Card.Img className='project-card-img' src={props.imgPath} alt="card-img" />}
      {props.youtubeURL && (
        <>
          <iframe
            className="no-print"
            src={props.youtubeURL}
            title="YouTube video player"
            frameBorder="0"
            style={{ width: '100%', height: '300px' }} // Adjust the height as needed
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="print-only mt-1 ">Video: <a href={props.demoLink} target="_blank">{props.demoLink}</a></div>
        </>
      )}
      <Card.Body>
        <h3>{props.title}</h3>
        <Card.Text style={{ textAlign: "justify", fontSize: "1.1em" }}>
          {props.description.split('\n').map((text, i) => (
            <span key={i}>
              {text}
              <br />
            </span>
          ))}
        </Card.Text>

        {props.ghLink && (
          <>
            <Button variant="primary" href={props.ghLink} target="_blank" className="no-print">
              <BsGithub style={{ marginBottom: 3 }} /> &nbsp;
              {props.isBlog ? "Blog" : "GitHub"}
            </Button>
            <div className="print-only">{props.isBlog ? "Blog: " : "GitHub: "}<a href={props.ghLink} target="_blank">{props.ghLink}</a></div>
          </>
        )}

        {!props.isBlog && props.demoLink && (
          <>
            <Button
              variant="primary"
              href={props.demoLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite style={{ marginBottom: 2 }} /> &nbsp;
              {"Demo"}
            </Button>
            <div className="print-only">Demo: <a href={props.demoLink} target="_blank">{props.demoLink}</a></div>
          </>
        )}
        {!props.isBlog && props.demoLink2 && (
          <>
            <Button
              variant="primary"
              href={props.demoLink2}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite style={{ marginBottom: 2 }} /> &nbsp;
              {"Demo 2"}
            </Button>
            <div className="print-only">Demo 2: <a href={props.demoLink2} target="_blank">{props.demoLink2}</a></div>
          </>
        )}
        {props.slideLink && (
          <>
            <Button
              variant="primary"
              href={props.slideLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <FaFilePowerpoint style={{ marginBottom: 3 }} /> &nbsp;
              {"Slides"}
            </Button>
            <div className="print-only">Slides: <a href={props.slideLink} target="_blank">{props.slideLink}</a></div>
          </>
        )}
        {props.downloadLink && (
          <>
            <Button
              variant="primary"
              href={props.downloadLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <FaCode style={{ marginBottom: 2 }} /> &nbsp;
              {"Source Code"}
            </Button>
            <div className="print-only">Source Code: <a href={props.downloadLink} target="_blank">{props.downloadLink}</a></div>
          </>
        )}
        {props.paperLink && (
          <>
            <Button
              variant="primary"
              href={props.paperLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <FaFilePdf style={{ marginBottom: 2 }} /> &nbsp;
              {"Paper"}
            </Button>
            <div className="print-only">Paper: <a href={props.paperLink} target="_blank">{props.paperLink}</a></div>
          </>
        )}
        {props.colabLink && (
          <>
            <Button
              variant="primary"
              href={props.colabLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <img style={{ marginBottom: 2 }} width="18" height="18" src="https://img.icons8.com/color/48/google-colab.png" alt="google-colab" />
              {" Colab"}
            </Button>
            <div className="print-only">Colab: <a href={props.colabLink} target="_blank">{props.colabLink}</a></div>
          </>
        )}
        {props.webLink && (
          <>
            <Button
              variant="primary"
              href={props.webLink}
              target="_blank"
              className="no-print"
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite style={{ marginBottom: 2 }} />
              {" Web"}
            </Button>
            <div className="print-only">Web: <a href={props.webLink} target="_blank">{props.webLink}</a></div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCardsSimplify;



