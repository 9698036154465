import React, { useState, useEffect, useMemo } from 'react';
import {
    Chat,
    Channel,
    ChannelList,
    Window,
    ChannelHeader,
    MessageList,
    MessageInput,
    Thread,
    LoadingIndicator,
    useChannelActionContext,
    useChannelStateContext,
    useTranslationContext
} from 'stream-chat-react';
import { useClient } from '../../hooks/useClient';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components'
import { apiBaseUrl } from '../../App';
import { CustomizedMessageInput } from './CustomizedMessageInput';
import { FaMicrophone } from "react-icons/fa";
import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark } from "react-icons/hi2";
const CustomStyledComponent = styled.div`
    #root {
        display:flex;
    }
    .str-chat__channel-list {
    width: 55%;
    }
`;

export const CustomMessageInput = (props) => {
    const { sendMessage } = useChannelActionContext();
    const { channel } = useChannelStateContext();
    const [isSending, setIsSending] = useState(false);
    const playSynthesizedAudio = async (text, langCode = 'en-US') => {
        const audioUrl = `${process.env.REACT_APP_SYNTHESIZE}?text=${encodeURIComponent(text)}&langCode=${encodeURIComponent(langCode)}`;
        try {
            console.log('audioUrl', audioUrl);
            const audio = new Audio(audioUrl);
            setIsPlaying(true)
            await audio.play();
            audio.onended = () => {
                setIsPlaying(false);
            };
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };
    const customSendMessage = async (messageObject) => {
        setIsSending(true); // Disable sending new messages

        // Check if the channel name is appropriate
        if (props.currentUser.id != 'umkc' && channel?.data?.id === `private_${props.currentUser.id}_umkc`) {
            try {
                setTranscript('');

                // Add your custom logic here
                console.log('Sending message:', messageObject);
                // Call the original sendMessage function
                await sendMessage(messageObject);

                console.log('currentUser', props.currentUser);
                const response = await fetch(`${process.env.REACT_APP_SMART_REPLY_URL}?userID=${props.currentUser.id}&threadID=${props.currentUser.thread_id}`);
                const data = await response.json();
                console.log('data from API:', data);
                if (data && data.answer) {
                    console.log('calling synthesize');
                    if (isTTSon)
                        await playSynthesizedAudio(data.answer);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setTranscript('');
                setIsSending(false); // Re-enable sending messages
            }
        } else {
            // console.log('channel', channel)
            // Handle the case where the channel name is not what you're looking for
            setIsSending(false);
            setTranscript('');
            // console.log('Message not sent: Channel name is not appropriate.');
            await sendMessage(messageObject);

        }
    };

    // Conditional placeholder text
    const placeholderText = isSending
        ? "Please wait while Nobidy is responding..."
        : "Type your message...";


    const [recognitionStatus, setRecognitionStatus] = useState('inactive'); // 'recording', 'stopping'

    const [transcript, setTranscript] = useState(''); // To store the transcribed text

    let speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    let recognition;

    if (speechRecognition) {
        recognition = new speechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.onresult = (event) => {
            let finalTranscript = '';
            let interimTranscript = '';

            for (const result of event.results) {
                // console.log("result", result[0].transcript)
                if (result.isFinal) {
                    finalTranscript += result[0].transcript + '.';
                } else {
                    interimTranscript += result[0].transcript;
                }
            }
            // console.log("finalTranscript + interimTranscript", finalTranscript + interimTranscript)
            setTranscript(finalTranscript + interimTranscript);
        };
        recognition.onstart = () => {
            console.log('recording...');

            setRecognitionStatus('recording');
        };

        recognition.onend = () => {
            console.log('stop!!!')
            setRecognitionStatus('inactive');
        };
        recognition.onspeechend = () => {
            recognition.stop();
            console.log('Speech recognition has stopped.');
        }
    } else {
        console.error("Speech recognition not supported in this browser.");
    }

    const toggleRecording = () => {
        if (recognitionStatus === 'stopping') {
            // Do nothing if the recognition is in the process of stopping
            return;
        }

        if (recognitionStatus === 'recording') {
            recognition.abort();
            setRecognitionStatus('stopping');
        } else {
            // initRecognition();
            recognition.start();
            setRecognitionStatus('recording');
        }
    };

    const stopRecording = () => {
        if (recognitionStatus === 'recording') {
            recognition.abort();
            setRecognitionStatus('stopping');
        }
    };
    //TTS
    const [isPlaying, setIsPlaying] = useState(false);
    const [isTTSon, setIsTTSOn] = useState(true);

    const togglePlay = () => {
        if (!isPlaying)
            setIsTTSOn(!isTTSon);
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div onClick={togglePlay} style={{ cursor: 'pointer' }}>
                {isTTSon || isPlaying ?
                    <HiOutlineSpeakerWave style={{ marginLeft: 11, marginBottom: 1, fontSize: 23, color: 'var(--imp-text-color)' }} /> :
                    <HiOutlineSpeakerXMark style={{ marginLeft: 11, marginBottom: 1, fontSize: 23, color: 'gray' }} />
                }
            </div>

            <FaMicrophone
                onClick={toggleRecording}
                disabled={isSending || recognitionStatus === 'stopping'}
                style={{
                    fontSize: 19,
                    marginLeft: 11,
                    color: recognitionStatus === 'recording' ? 'red' :
                        recognitionStatus === 'stopping' ? 'orange' : 'gray',
                    cursor: recognitionStatus === 'stopping' ? 'not-allowed' : 'pointer'
                }}
            />
            <MessageInput
                {...props}
                overrideSubmitHandler={customSendMessage}
                hideSendButton={isSending}
                disabled={isSending}
                additionalTextareaProps={{
                    placeholder: placeholderText, value: transcript, onChange: (e) => {
                        setTranscript(e.target.value)
                        stopRecording();
                    }
                }}
                value={transcript}

            />

        </div>

    );
};
const CompactChatWindow = ({ currentUser, loading, chatClient, setMessengerOpen }) => {

    const user = useMemo(() => currentUser ? {
        id: currentUser.id,
        name: currentUser.userName,
        image: currentUser.userImage,
        role: currentUser.role
    } : null, [currentUser]);
    // const userToken = currentUser ? currentUser.userToken : null;
    const CustomList = (props) => {
        const [showModal, setShowModal] = useState(false);

        const { children, error, loading, LoadingErrorIndicator, LoadingIndicator } = props;

        if (error) {
            return <LoadingErrorIndicator text='Loading Error - check your connection.' type='connection' />;
        }

        if (loading) {
            return <LoadingIndicator />;
        }

        return <div style={{ fontSize: 12, width: '100%' }}>
            {user && (
                <div style={{ marginLeft: 6, fontSize: 11 }}>
                    <p>
                        Your Username: <b style={{ color: 'var(--imp-text-color)' }}>{user.name}</b> - [<code style={{ color: 'gray' }}>{user.id}</code>] ({user.role})
                    </p>

                    <small>
                        Note: For {" "}<span style={{ textDecoration: 'underline' }}>direct messages</span>, please try to keep this window open. Your username will be
                        <span style={{ fontWeight: 'bold' }}> randomized</span> on refresh and the conversation will be {" "}
                        <span style={{ textDecoration: 'underline' }}>deleted</span>.
                    </small>
                </div>
            )}
            <div style={{ textAlign: 'center' }} >
                <Button size='sm' className='mb-1' onClick={() => setMessengerOpen(true)}>
                    Full Screen
                </Button> {" "}
                <Button size='sm' variant='secondary' className='mb-1' onClick={() => {
                    setShowModal(true);
                }}>
                    Randomize user
                </Button>
            </div>
            <ClearDataModal user={user} setShowModal={setShowModal} showModal={showModal} />
            {children}
        </div >
    };




    const createFilters = () => {
        let baseFilters = {
            type: 'messaging',
            members: { $in: [chatClient?.userID] }
        };

        if (currentUser?.role === 'admin') {
            baseFilters = {
                ...baseFilters,
                $or: [
                    // Condition for public channels
                    {
                        channelType: 'public'
                    },
                    // Condition for private channels with messages
                    {
                        channelType: 'private',
                        last_message_at: { $exists: true }
                    }
                ]
            };
        }

        return baseFilters;
    };


    if (loading || !chatClient) {
        return <LoadingIndicator />;
    }

    const filters = createFilters();
    const sort = { last_message_at: -1 };

    return <CustomStyledComponent><div className='compact-chat-window'>
        <Chat client={chatClient} theme='messaging light'>
            <ChannelList
                List={CustomList}
                filters={filters}
                sort={sort} />
            <Channel>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    {/* <MessageInput /> */}
                    <CustomMessageInput currentUser={currentUser} />
                    {/* <CustomMessageInputWithMicrophone /> */}
                    {/* <CustomizedMessageInput /> */}
                </Window>
                <Thread />
            </Channel>
        </Chat>
    </div>

    </CustomStyledComponent >

    {/* Chat window */ }


};

export default CompactChatWindow;

const ClearDataModal = ({ user, setShowModal, showModal }) => {

    const handleClose = () => setShowModal(false);
    const handleConfirm = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm User Randomization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {`Are you sure you want to randomize the user? \nThis will clear all current conversations for ${user.name}`}  [<code style={{ color: 'gray' }}>{user.id}</code>].
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        No, keep my conversations.
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Yes, I want a new username.
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
