import { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';

export const useClient = ({ apiKey, user }) => {
    const [chatClient, setChatClient] = useState();
    useEffect(() => {
        console.log('user', user)
        if (user && user.id) {
            const client = new StreamChat(apiKey);
            let didUserConnectInterrupt = false;

            const connectionPromise = client
                .connectUser(user, user?.userToken)
                // .setGuestUser({ id: user.id })
                // .connectAnonymousUser()/
                .then(async (connectResponse) => {
                    if (!didUserConnectInterrupt) {
                        setChatClient(client);
                    }

                });

            return () => {
                didUserConnectInterrupt = true;
                setChatClient(undefined);
                connectionPromise
                    .then(() => client.disconnectUser())
                    .then(() => {
                        console.log('connection closed');
                    });
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
    }, [apiKey, user]);

    return chatClient;
};
