import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Duy Ho - Latest.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFViewer() {
  const [numPages, setNumPages] = useState(null);
  const width = window.innerWidth; // Or however you're getting the width

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Row className="resume flex-column justify-content-center align-items-center">
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <div className="d-flex justify-content-center" key={`page_${index + 1}`}>
            <Page
              pageNumber={index + 1}
              scale={width > 786 ? 1.7 : 0.6}
            />
          </div>
        ))}
      </Document>
    </Row>
  );
}
function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  return (<Container fluid className="resume-section">
    <Particle />
    <Row style={{ justifyContent: "center", position: "relative", marginTop: -70 }}>
      <PDFViewer />

      <Button
        variant="primary"
        href={pdf}
        target="_blank"
      // style={{ maxWidth: "250px" }}
      >
        <AiOutlineDownload />
        &nbsp;Download CV
      </Button>
    </Row>
  </Container>);
}

export default ResumeNew;
