import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaCode, FaFilePdf, FaFilePowerpoint } from "react-icons/fa";
import colablogo from "../../Assets/Logos/colab.svg";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {props.imgPath && <Card.Img className='project-card-img' src={props.imgPath} alt="card-img" />}
      {props.youtubeURL && <iframe
        src={props.youtubeURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify", fontSize: "14px" }}>
          {props.description.split('\n').map((text, i) => (
            <span key={i}>
              {text}
              <br />
            </span>
          ))}
        </Card.Text>

        {props.ghLink && <Button variant="primary" href={props.ghLink} target="_blank">
          <BsGithub style={{ marginBottom: 3 }} /> &nbsp;
          {props.isBlog ? "Blog" : "GitHub"}
        </Button>}
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite style={{ marginBottom: 2 }} /> &nbsp;
            {"Demo"}
          </Button>
        )}
        {props.slideLink && (
          <Button
            variant="primary"
            href={props.slideLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <FaFilePowerpoint style={{ marginBottom: 3 }} /> &nbsp;
            {"Slides"}
          </Button>
        )}
        {props.downloadLink && (
          <Button
            variant="primary"
            href={props.downloadLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <FaCode style={{ marginBottom: 2 }} /> &nbsp;
            {"Source Code"}
          </Button>
        )}
        {props.paperLink && (
          <Button
            variant="primary"
            href={props.paperLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <FaFilePdf style={{ marginBottom: 2 }} /> &nbsp;
            {"Paper"}
          </Button>
        )}
        {props.colabLink && (
          <Button
            variant="primary"
            href={props.colabLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <img style={{ marginBottom: 2 }} width="18" height="18" src="https://img.icons8.com/color/48/google-colab.png" alt="google-colab" />
            {" Colab"}
          </Button>
        )}
        {props.webLink && (
          <Button
            variant="primary"
            href={props.colabLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite style={{ marginBottom: 2 }} />
            {" Web"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;

