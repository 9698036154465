import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCardsSimplify";
import Particle from "../Particle";
import soar from "../../Assets/Projects/soar.png";
import welisten from "../../Assets/Projects/WeListen.jpg";
import cyberviz from "../../Assets/Projects/cyberviz1.jpeg";
import cybervizvideo from "../../Assets/Projects/cyberviz-demo.mp4";
import cybervizvideo2 from "../../Assets/Projects/cyberviz-demo-2.mp4";
import collabfireagent from "../../Assets/Projects/Collab Fire Agent.gif";
import dynahealth from "../../Assets/Projects/Dynahealth.png";
import { allProjects } from "./Projects";



function ProjectsAcademic() {
    return (
        <div className="project-section" style={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
            <Particle />
            <a href='https://duyhho.com/projects' className='print-only'>https://duyhho.com/projects</a>
            <h1 className="project-heading">
                My <strong className="purple">Projects </strong>
            </h1>
            <p style={{ color: "black" }}>
                Here are a few projects I've worked on recently.
            </p>
            <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                {allProjects.map(project => (
                    <Col md={project.colSize || 12} className="project-card-academic" key={project.title}>
                        <ProjectCard
                            imgPath={project.imgPath}
                            isBlog={project.isBlog || false}
                            title={project.title}
                            description={project.description}
                            ghLink={project.ghLink}
                            demoLink={project.demoLink}
                            youtubeURL={project.youtubeURL}
                            webLink={project.webLink}
                            colabLink={project.colabLink}
                            slideLink={project.slideLink}
                            downloadLink={project.downloadLink}
                        // ...other properties
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default ProjectsAcademic;
