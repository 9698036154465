import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
function HomeAcademic({ setChatOpen, isMobile = false }) {
    const customStyle = {
        border: 'none',
        listStyleType: 'disc',
        paddingLeft: '20px'
    };
    const [showModal, setShowModal] = useState(false);
    // Close Modal
    const handleCloseModal = () => setShowModal(false);
    return <div className='mb-1 home-about-body'>
        <h1 className="mt-5">Introduction</h1>
        <div className='home-about-body'>
            <p>
                I am currently a Ph.D candidate majoring in Computer Science at University of Missouri-Kansas City, under the supervision of
                {" "}<a href="https://sse.umkc.edu/profiles/lee-yugyung.html">Dr. Yugyung Lee
                </a> [<a href="https://scholar.google.com/citations?user=BwE_CWcAAAAJ&hl=en">Google Scholar</a>]
                .
            </p>
            <p>
                Throughout my academic career, I have grown my interests, developed my skills, and specialized in <b>
                    Data Science, Artificial Intelligence, Deep Learning, and Robotics</b>.
                I have worked on many research projects that focused on <i>Object Detection/Image Segmentation</i>, <i>Reinforcement/Imitation Learning</i>, <i>AI-Empowered Robotics</i>, and <i>Augmented/Virtual/Mixed Reality</i>.
            </p>
            <p>
                In addition to research, I have participated actively in many interdisciplinary projects and taught several university undergraduate and graduate courses in areas including <i>Algorithms</i>, <i>3D modeling</i>, <i>3D simulation</i>, <i>AR/VR</i>, <i>Web and Mobile Development</i>, <i>Smart Connected Communities</i>, and <i>AI for Cybersecurity</i>.
            </p>

        </div>
        <h2 className="mt-5">Publications</h2>


        <div className='mb-1 home-about-body'>
            <ul>
                <li>
                    <strong>Surmounting Obstacles for Academic Resilience: A Dynamic Portal for Supporting an Alliance of Students with Disabilities</strong><br />
                    Alexis N Petri, <strong>Duy H Ho</strong>, Ye Wang, Yugyung Lee<br />
                    The International Conference on Human-Computer Interaction, 2023 [ <a href='https://link.springer.com/chapter/10.1007/978-3-031-35897-5_26'>Paper</a> ]<br />
                    Research Impact Score: 1.801, Contributing Best Scientists: 531, H5-index: 871, Research Ranking (Computer Science): 3911
                </li>
                <li>
                    <strong>A case study of using natural language processing to extract consumer insights from tweets in American cities for public health crises</strong><br />
                    Ye Wang, Erin Willis, Vijaya K Yeruva, <strong>Duy H Ho</strong>, Yugyung Lee<br />
                    BMC Public Health, 2023 [ <a href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-023-15882-7">Paper</a> ]<br />
                    Impact Factor: 4.51, 5-year Impact Factor: 4.71, SJR: 1.3071, Ranking: Q1 in Public Health, Environmental and Occupational Health
                </li>
                <li>
                    <strong>OpenComm: Open community platform for data integration and privacy preserving for 311 calls</strong><br />
                    <strong>Duy H Ho</strong>, Yugyung Lee, Srichakradhar Nagireddy, Charan Thota, Brent Never, Ye Wang<br />
                    Sustainable Cities and Society, 2022 [ <a href="https://www.sciencedirect.com/science/article/pii/S2210670722001858">Paper</a> ]<br />
                    Impact Factor: 11.76, SJR: 2.3054, Ranking: Q1 in Renewable Energy, Sustainability and the Environment
                </li>
                <li>
                    <strong>Deep learning visual methods for identifying abandoned houses</strong><br />
                    Jim DeLisle, Hye-Sung Han, <strong>Duy H Ho</strong>, Yugyung Lee, Brent Never, Ye Wang.<br />
                    Cityscape, 2022 [ <a href="https://www.jstor.org/stable/48680615">Paper</a> ]<br />
                    Published by the US Department of Housing & Urban Development.
                </li>
                <li>
                    <strong>Community-in-the-loop: Creating Artificial Process Intelligence for Co-production of City Service</strong><br />
                    Ye Wang, Srichakradhar Reddy Nagireddy, Charan Tej Thota, <strong>Duy H Ho</strong>, Yugyung Lee<br />
                    Proceedings of the ACM on Human-Computer Interaction, 2022 [ <a href="https://dl.acm.org/doi/abs/10.1145/3555176">Paper</a> ]<br />
                    Impact Score: 3.7410, SJR: 0.71510, Ranking: Q1 in Social Sciences (miscellaneous); Q2 in Computer Networks and Communications and Human-Computer Interaction
                </li>
                <li>
                    <strong>Big data analytics framework for predictive analytics using public data with privacy preserving</strong><br />
                    <strong>Duy H Ho</strong>, Yugyung Lee<br />
                    2021 IEEE International Conference on Big Data, 2021 [ <a href="https://ieeexplore.ieee.org/abstract/document/9671997">Paper</a> ]<br />
                    Research Impact Score: 4.1015
                </li>
                <li>
                    <strong>DeepLite: Real-time deep learning framework for neighborhood analysis</strong><br />
                    <strong>Duy H Ho</strong>, Raj Marri, Sirisha Rella, Yugyung Lee<br />
                    2019 IEEE International Conference on Big Data, 2019 [ <a href="https://ieeexplore.ieee.org/abstract/document/9005651">Paper</a> ]<br />
                    Research Impact Score: 4.1015
                </li>
                <li>
                    <strong>Graphevo: Characterizing and understanding software evolution using call graphs</strong><br />
                    Vijay Walunj, Gharib Gharibi, <strong>Duy H Ho</strong>, Yugyung Lee<br />
                    2019 IEEE International Conference on Big Data, 2019 [ <a href="https://ieeexplore.ieee.org/abstract/document/9005560">Paper</a> ]<br />
                    Research Impact Score: 4.1015
                </li>
            </ul>


        </div>
        <div>
            <h2>Contact</h2>
            <p>Please feel free to email me any time, or click <Button size='sm' variant='outline-primary'
                onClick={() => {
                    if (isMobile == false) {
                        setChatOpen(true)

                    }
                    else {
                        setShowModal(true)
                    }
                }}>Chat With Me</Button> for ideas, collaboration, or research discussion. </p>

        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Real-time chat is currently NOT supported on mobile.
                <br></br>
                Please use a web browser on a desktop or laptop for the chat feature.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>




}

export default HomeAcademic;
