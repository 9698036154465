import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import ResearchStatement from "./components/Resume/ResearchStatement";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import 'stream-chat-react/dist/css/v2/index.css';
import './layout.css';
import "./style.css";
import "./App.css";


import MessengerChat from "./components/Chat/MessengerChat";
import { Row, Col, Container, } from "react-bootstrap";
import CompactChat from "./components/Chat/CompactChat";
import { useClient } from './hooks/useClient'; // Adjust the import path as necessary
import { Chat, LoadingIndicator } from "stream-chat-react";
import HomeAcademic from "./components/Home/HomeAcademic";
import SideBar from "./components/Sidebar";
import ProjectsAcademic from "./components/Projects/ProjectsAcademic";
import { Form } from 'react-bootstrap';

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const adminUser = {
  id: 'umkc',
  userName: 'Duy Ho',
  userToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidW1rYyJ9.5D7dCHEbRPjjhbfZWT2H3iwunnZcqAximzRU8noubkw',
  image: "https://i.ibb.co/1QCyvBY/avatar3.png",
  role: 'admin'
};
const saveUserData = (data) => {
  const now = new Date();
  const item = {
    value: data,
    expiry: now.getTime() + 1000 * 60 * 60 * 72, // Expires in 72 hours
  };
  localStorage.setItem('userInfo', JSON.stringify(item));
};

function App() {
  const [load, setLoad] = useState(true);
  const [isChatOpen, setChatOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);

  let version = urlParams.get('version');
  console.log('version', version)
  if (version == 'industry') {
    version = 'v2'
  }
  else {
    version = 'v1'
  }

  const [isToggled, setIsToggled] = useState(version == 'v1');
  const [isMobile, setIsMobile] = useState(false);
  const [isWide, setIsWide] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // 768px is a common breakpoint for mobile devices
  };

  // Handle window resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    console.log('isChatOpen App.js', isChatOpen)
  }, [isChatOpen])
  useEffect(() => {

    const fetchUser = async () => {
      setLoading(true);
      try {
        const role = urlParams.get('role');
        // console.log('process.env', process.env)
        console.log('`${process.env.REACT_APP_RETURN_NEW_THREAD_URL}`', `${process.env.REACT_APP_RETURN_NEW_THREAD_URL}`)

        // const thread_response = await fetch(`${process.env.REACT_APP_RETURN_NEW_THREAD_URL}`);
        // const thread_data = await thread_response.json();
        if (role === 'admin') {
          setCurrentUser(adminUser);
        } else {
          const savedItem = localStorage.getItem('userInfo');
          // const savedItem = null
          if (savedItem) {
            const item = JSON.parse(savedItem);
            const now = new Date();

            if (now.getTime() > item.expiry) {
              // If the item is expired, clear it from storage and fetch new data
              localStorage.removeItem('userInfo');
              const response = await fetch(`${process.env.REACT_APP_ASSIGN_USER_URL}`);
              const data = await response.json();
              console.log(`data from ${`${process.env.REACT_APP_ASSIGN_USER_URL}`}`, data)
              if (data && data.user) {
                const newUser = {
                  id: data.user.id,
                  userName: data.user.name,
                  image: `https://getstream.io/random_png/?id=${data.user.id}&name=${data.user.name}`,
                  userToken: data.user.token,
                  role: data.user.role,
                  thread_id: data.user.thread_id
                }
                setCurrentUser({ ...newUser });
                saveUserData({ ...newUser })

              }
            } else {
              setCurrentUser({ ...item.value });
              setLoading(false);
            }
          }
          else {
            const response = await fetch(`${process.env.REACT_APP_ASSIGN_USER_URL}`);
            const data = await response.json();
            console.log('data', data)
            if (data && data.user) {
              const newUser = {
                id: data.user.id,
                userName: data.user.name,
                image: `https://getstream.io/random_png/?id=${data.user.id}&name=${data.user.name}`,
                userToken: data.user.token,
                role: data.user.role,
                thread_id: data.user.thread_id
              }
              setCurrentUser({ ...newUser });
              saveUserData({ ...newUser })
            }
          }


        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  const chatClient = useClient({
    apiKey: 'gawfgbhdkdeb',
    user: currentUser,
    // tokenOrProvider: currentUser?.userToken,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      {load && <Preloader load={load} />}


      <Routes>

        <Route path="*" element={
          <div className="App print-container" id={load ? "no-scroll" : "scroll"}>
            <Navbar
              isToggled={isToggled}
              setIsToggled={setIsToggled}
              isWide={isWide}
              setIsWide={setIsWide}
            />

            <Container fluid={isWide} style={{ marginTop: '61px', height: 'auto' }}>
              <Row>
                {isToggled &&
                  <Col md={isWide ? "2" : "3"}>
                    <SideBar />
                  </Col>
                }
                <Col md={isToggled ? (isWide ? '10' : '9') : '12'}>

                  <Routes>
                    <Route path="/" element={isToggled ? <HomeAcademic setChatOpen={setChatOpen} isMobile={isMobile} /> : <Home />} />

                    <Route path="/project" element={isToggled ? <ProjectsAcademic /> : <Projects />} />
                    <Route path="/projects" element={isToggled ? <ProjectsAcademic /> : <Projects />} />

                    {/* Assuming both versions of the website use the same component for "/about" and "/resume" */}
                    <Route path="/about" element={<About />} />
                    <Route path="/resume" element={<Resume />} />
                    <Route path="/research-statement" element={<ResearchStatement />} />

                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Col>
              </Row>
            </Container>
            <Footer />
            {/* If you want CompactChat inside the above routing context, move it here */}
          </div>
        } />

      </Routes>

      {chatClient && !isMobile && <Chat client={chatClient}>
        <CompactChat currentUser={currentUser} loading={loading} setChatOpen={setChatOpen} isChatOpen={isChatOpen} />
      </Chat>}
    </Router>

  );
}


export default App;
